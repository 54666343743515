// @flow
import { css } from "styled-components";
import { media } from "@nested/brand";
import { ResponsiveImage } from "../../components/ResponsiveImage";

const wrapperStyle = css`
  width: 100%;
  background: transparent;
  margin: 86px auto 30px auto;
  ${media.tablet`
   margin-top: 130px;
   margin-bottom: 75px;
  `}
`;

const quoteStyle = css`
  text-align: center;
  line-height: 27px;
  width: calc(100% - 40px);
  font-size: 18px;
  margin: 0 auto;
  & span {
    color: ${({ theme }) => theme.palette.blue150};
    font-weight: 500;
  }
  ${media.tablet`
    width: calc(100% - 140px);
    line-height: 37px;
    font-size: 22px;
  `}
  ${media.desktop`
    width: calc(100% - 320px);
  `}
  ${media.maxDesktop`
    width: 747px;
  `}
`;

const signatureStyle = css`
  margin-top: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  & p {
    margin-left: 20px;
  }
  & span {
    color: ${({ theme }) => theme.palette.hague40};
    font-weight: 400;
  }
  ${media.tablet`
    font-size: 22px;
  `}
`;

const avatarStyle = css`
  width: 40px;
  height: 40px;
  ${media.tablet`
    width: 59px;
    height: 59px
`}
`;

type Props = {
  quoteText: string,
  employeeName: string,
  employeeJobTitle: string,
  employeeAvatar: PrismicImage,
};

export const EmployeeQuote = ({
  quoteText,
  employeeName,
  employeeJobTitle,
  employeeAvatar,
}: Props) => {
  return (
    <div css={wrapperStyle}>
      <p css={quoteStyle}>
        <span>“</span>
        {quoteText}
        <span>”</span>
      </p>
      <div css={signatureStyle}>
        {employeeAvatar.url && (
          <ResponsiveImage
            src={employeeAvatar.url}
            alt={employeeAvatar.alt}
            css={avatarStyle}
          />
        )}
        <p>
          {employeeName}, <span>{employeeJobTitle}</span>
        </p>
      </div>
    </div>
  );
};
